const BASE_URL = '/'

const AUTH = BASE_URL

const PASSWORD_RECOVERY = `${BASE_URL}password-recovery`
const NEW_PASSWORD = `${BASE_URL}new-password/:key`

const HOME = `${BASE_URL}home`
// Usuários
const USERS = `${BASE_URL}users`
const UNITIES = `${USERS}/unities`
// Financeiro
const FINANCIALS = `${BASE_URL}financials`
const FINANCIALS_HOME = `${FINANCIALS}/home`
const FINANCIALS_BILLETS = `${FINANCIALS}/billets`
const FINANCIALS_INCOME_TAX = `${FINANCIALS}/income-tax`
const FINANCIALS_STATEMENTS = `${FINANCIALS}/statements`
const FINANCIALS_READJUSTMENTS = `${FINANCIALS}/readjustments`
// Atendimento
const SUPPORT = `${BASE_URL}supports`
const SUPPORT_FAQ = `${BASE_URL}faq`
// Novo chamado
const SUPPORT_NEW_TICKET_WARRANTIES = `${SUPPORT}/warranties`
const SUPPORT_TICKET_EXTERNAL = `${SUPPORT}/tickets/use-code/:uuid`
const SUPPORT_TICKET_LIST = `${SUPPORT}/tickets`
const SUPPORT_TICKET_NEW = `${SUPPORT_TICKET_LIST}/new`
const SUPPORT_TICKET_DETAILS = `${SUPPORT_TICKET_LIST}/:uuid`

const WARRANTIES = `${BASE_URL}warranties`

// Noticias
const NEWS = `${BASE_URL}news`
const NEWS_CONSTRUCTION = `${NEWS}/constructions`
const NEWS_CONSTRUCTION_DETAILS = `${NEWS_CONSTRUCTION}/:uuid`
const NEWS_INFORMATIVE = `${NEWS}/informatives`
const NEWS_INFORMATIVE_DETAILS = `${NEWS_INFORMATIVE}/:uuid`

// Documents
const DOCS = `${BASE_URL}documents`
// Sem Autorização
const UNAUTHORIZED = `${BASE_URL}not-authorized`

export default {
  BASE_URL,
  AUTH,
  PASSWORD_RECOVERY,
  NEW_PASSWORD,
  HOME,
  USERS,
  UNITIES,
  FINANCIALS,
  FINANCIALS_HOME,
  FINANCIALS_BILLETS,
  FINANCIALS_INCOME_TAX,
  FINANCIALS_STATEMENTS,
  FINANCIALS_READJUSTMENTS,
  SUPPORT,
  SUPPORT_NEW_TICKET_WARRANTIES,
  SUPPORT_TICKET_NEW,
  SUPPORT_TICKET_EXTERNAL,
  SUPPORT_TICKET_LIST,
  SUPPORT_TICKET_DETAILS,
  SUPPORT_FAQ,
  // Notícias
  NEWS,
  NEWS_CONSTRUCTION,
  NEWS_CONSTRUCTION_DETAILS,
  NEWS_INFORMATIVE,
  NEWS_INFORMATIVE_DETAILS,
  // Documentos
  DOCS,

  WARRANTIES,
  UNAUTHORIZED,
}

import api from '@/services/api'
import auth from '@/store/modules/auth'

export default {
  state: {},

  mutations: {},

  actions: {
    async getUser({ commit }, uuid) {
      try {
        const response = await api.get(`/portal/users/${uuid}`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async updateUser({ commit }, user) {
      try {
        return await api.put(`/portal/users/${user.id}`, user)
      } catch (error) {
        throw error
      }
    },

    async updatePassword({ commit }, user) {
      try {
        return await api.put(`/portal/users/${user.uuid}/password`, user)
      } catch (error) {
        throw error
      }
    },

    async setFirebaseToken({ commit }, user) {
      try {
        const response = await api.put(
          `/portal/users/${user.uuid}/firebase-token`,
          user,
        )
        commit('SET_FIREBASE_TOKEN', user.firebaseToken)
        return response
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}

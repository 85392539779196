<template>
  <div class="page-content" :class="{ 'vh-100': addClass }">
    <Sidebar v-if="showComponent" :notifications="notifications" />
    <div class="content-wrapper">
      <div class="content-inner">
        <div
          class="content"
          :class="{
            'd-flex justify-content-center align-items-center': addClass,
          }"
        >
          <slot />
        </div>
      </div>
      <MenuMobile v-if="showComponent && isMobile" />
    </div>
  </div>
  <NotificationSidebar v-if="isLoggedPdCIn" :notifications="notifications" />
  <Firebase v-if="isLoggedPdCIn" />
</template>
<style>
@media (max-width: 990px) {
  .content {
    padding: 0;
  }
}
</style>
<script>
import Sidebar from '@/components/commons/Sidebar.vue'
import MenuMobile from '@/components/commons/MenuMobile.vue'
import NotificationSidebar from '@/components/commons/NotificationSidebar.vue'
// import Firebase from '@/components/commons/Firebase.vue'

export default {
  name: 'Layout',

  components: {
    Sidebar,
    MenuMobile,
    NotificationSidebar,
    // Firebase,
  },

  data() {
    return {
      notifications: [],
      // showComponent: false,
      isMobile: false,
      routesName: [
        'Auth',
        'PasswordRecovery',
        'NewPassword',
        'Unauthorized',
        'NotFound',
        'ExternalSupportTicket',
      ],
    }
  },

  methods: {
    async getNotifications() {
      try {
        if (this.isLoggedPdCIn) {
          this.notifications = await this.$store.dispatch('getNotifications')
        }
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    onResize() {
      this.isMobile = window.innerWidth < 991
    },
  },
  computed: {
    isLoggedPdCIn() {
      return this.$store.getters.isLoggedPdCIn
    },
    addClass() {
      return this.routesName.includes(this.$route.name)
    },

    showComponent() {
      return !this.routesName.includes(this.$route.name)
    },
  },

  mounted() {
    this.onResize()
    this.getNotifications()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
}
</script>

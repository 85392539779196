import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getProblemsWarranties({ commit }, data) {
      try {
        const query = new URLSearchParams(data)
        const response = await api.get(
          `/portal/tickets/warranties/problems?${query}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getProblemsWarrantiesExternal({ commit }, data) {
      try {
        const query = new URLSearchParams(data)
        const response = await api.get(
          `/clients/tickets/warranties/problems?${query}`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getlocalsWarranties({ commit }, id) {
      try {
        const response = await api.get(
          `/portal/tickets/warranties/items/${id}/locals`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getLocalsWarrantiesExternal({ commit }, id) {
      try {
        const response = await api.get(
          `/clients/tickets/warranties/items/${id}/locals`,
        )

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async verifyWarranty({ commit }, data) {
      try {
        const query = new URLSearchParams(data.unity)
        const response = await api.get(
          `/portal/tickets/warranties/items/${data.warrantyItemId}/validate?${query}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async verifyWarrantyExternal({ commit }, data) {
      try {
        const query = new URLSearchParams(data.unity)
        const response = await api.get(
          `/clients/tickets/warranties/items/${data.warrantyItemId}/validate?${query}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async getPreventiveMaintenanceStatus({ commit }, data) {
      try {
        const query = new URLSearchParams(data.unity)
        const response = await api.get(
          `/portal/warranties/preventive-maintenance?${query}`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },

    async registerPreventiveMaintenance({ commit }, form) {
      try {
        return await api.post(`/portal/warranties/preventive-maintenance`, form)
      } catch (error) {
        throw error
      }
    },

    async openTicketWarranties({ commit }, form) {
      try {
        return await api.post(`/portal/tickets/warranties`, form)
      } catch (error) {
        throw error
      }
    },
    async openTicketWarrantiesExternal({ commit }, form) {
      try {
        return await api.post(`/clients/tickets/warranties`, form)
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}

<template>
  <!-- Notifications -->
  <div class="offcanvas offcanvas-end" tabindex="-1" id="notifications">
    <div class="offcanvas-header py-0">
      <h5 class="offcanvas-title py-3">Notificações</h5>
      <button
        type="button"
        class="btn btn-light btn-sm btn-icon border-transparent rounded-pill"
        data-bs-dismiss="offcanvas"
      >
        <i class="ph-x"></i>
      </button>
    </div>
    <div class="of">
      <div class="offcanvas-body p-0" v-if="notifications.pedingMaintence">
        <div class="bg-light fw-medium py-2 px-3">Atendimentos</div>
        <div
          v-for="notification in notifications.ticketNewMessage"
          :key="notification.ticketUuid"
          class="p-3"
        >
          <router-link
            :to="`/supports/tickets/${notification.ticketUuid}`"
            class="navbar-nav-link rounded"
          >
            <div class="d-flex align-items-start mb-1">
              <div class="flex-fill">
                Seu atendimento de protocolo
                <span class="fw-semibold">{{
                  notification.ticketProtocol
                }}</span>
                sobre
                <span class="fw-semibold">{{
                  notification.ticketSubjectName
                }}</span>
                recebeu uma nova mensagem.

                <div class="fs-sm text-muted mt-1">
                  {{ dateTimeSqlToDateTimeBR(notification.lastInteraction) }}
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div class="offcanvas-body p-0" v-if="notifications.pedingMaintence">
        <div class="bg-light fw-medium py-2 px-3">Manutenção Preventiva</div>
        <div
          v-for="notification in notifications.pedingMaintence"
          :key="notification.ticketUuid"
          class="p-3"
        >
          <router-link :to="`/warranties`" class="navbar-nav-link rounded">
            <div class="d-flex align-items-start mb-1">
              <div class="flex-fill">
                Manutenção preventiva pendente no item
                <span class="fw-semibold">{{ notification.nameItem }}</span>
                com a tarefa
                <span class="fw-semibold">{{ notification.nameTask }}</span>
                até dia
                <span class="fw-semibold">{{ notification.submitEnd }}</span
                >.
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- /notifications -->
</template>

<script>
import { dateTimeSqlToDateTimeBR } from '@/utils/date'

export default {
  name: 'NotificationSidebar',
  props: {
    notifications: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateTimeSqlToDateTimeBR,
    }
  },
}
</script>

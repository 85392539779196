<template>
  <Layout>
    <router-view />
  </Layout>
  <Configuration />

  <Notification ref="notification" />
</template>

<script>
import '@/assets/fonts/inter/inter.css'
import '@/assets/icons/phosphor/styles.min.css'
import '@/assets/template/css/all.min.css'
import '@/assets/css/style.css'
import '@/assets/template/js/app'
import '@/assets/template/js/configuration'
import '@/assets/template/js/datatables'

import Layout from '@/views/layout/Layout.vue'
import Configuration from '@/components/commons/Configuration.vue'
import Notification from '@/components/commons/Notification.vue'

export default {
  name: 'App',

  components: {
    Layout,
    Configuration,
    Notification,
  },
}
</script>

<template>
  <form class="login-form" @submit.prevent="auth">
    <div class="mb-0">
      <div class="card-body">
        <div class="text-center mb-3">
          <div
            class="d-inline-flex align-items-center justify-content-center my-2"
          >
            <img
              src="@/assets/img/horizon_logo_color.png"
              class="h-80px"
              alt="Portal do Cliente"
            />
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label">E-mail</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="text"
              class="form-control"
              :class="{ 'is-invalid': v$.email.$errors.length }"
              placeholder="usuario@email.com"
              v-model="v$.email.$model"
            />
            <div class="form-control-feedback-icon">
              <i class="ph-envelope text-muted" />
            </div>
            <span v-if="v$.email.required.$invalid" class="invalid-feedback">
              O campo é obrigatório
            </span>
            <span v-if="v$.email.email.$invalid" class="invalid-feedback">
              Formato de e-mail inválido
            </span>
          </div>
        </div>
        <div class="mb-3">
          <label class="form-label">Senha</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="password"
              class="form-control"
              :class="{ 'is-invalid': v$.password.$errors.length }"
              placeholder="•••••••••••"
              v-model="v$.password.$model"
            />
            <div class="form-control-feedback-icon">
              <i class="ph-lock text-muted" />
            </div>
            <span v-if="v$.password.required.$invalid" class="invalid-feedback">
              O campo é obrigatório
            </span>
            <span
              v-if="v$.password.minLength.$invalid"
              class="invalid-feedback"
            >
              A senha deve ter pelo menos
              {{ v$.password.minLength.$params.min }} caracteres
            </span>
          </div>
        </div>
        <div class="mb-3">
          <button
            type="submit"
            class="btn btn-primary rounded-pill w-100"
            title="Login"
            :disabled="v$.$invalid || disableButton"
          >
            <span v-if="disableButton">
              <i class="ph-spinner spinner" />
            </span>
            <span v-else>Login</span>
          </button>
        </div>
        <div class="text-center" title="Esqueceu sua senha?">
          <router-link to="/password-recovery">
            Esqueceu sua senha?
          </router-link>
        </div>
        <div class="mb-3"></div>
      </div>
    </div>
    <div class="text-center">
      <button
        type="button"
        class="btn btn-success rounded-pill text-center"
        title="Login"
        @click="clickCallback"
      >
        <span>Instalar</span>
      </button>
      <VueAddtohomescreen title="Adicionar a Tela Inicial" />
    </div>
  </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import VueAddtohomescreen from '@owliehq/vue-addtohomescreen'
import PATHS from '@/router/routesMap'

export default {
  name: 'Auth',

  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    VueAddtohomescreen,
  },

  data() {
    return {
      deferredPrompt: null,
      email: '',
      password: '',
      disableButton: false,
    }
  },

  methods: {
    captureEvent() {
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault()
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e
      })
    },
    clickCallback() {
      // Show the prompt
      this.deferredPrompt.prompt()
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // Add analyticcs event
          this.$gtag.event('add_to_home_screen')
        }
        this.deferredPrompt = null
      })
    },
    async auth() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          await this.$store.dispatch('auth', {
            email: this.email,
            password: this.password,
          })
          this.disableButton = false
          const redirectRoute = localStorage.getItem('routePdC')
          if (redirectRoute) {
            localStorage.removeItem('routePdC')
            window.location.href = redirectRoute
            return
          }
          this.$router.push(PATHS.UNITIES)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations() {
    return {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    }
  },

  mounted() {
    this.captureEvent()
    if (localStorage.getItem('sessionError')) {
      this.$root.$refs.notification.warning(
        localStorage.getItem('sessionError'),
      )
      localStorage.removeItem('sessionError')
    }
  },
}
</script>

import auth from './modules/auth'
import users from './modules/users'
import financials from './modules/financials'
import unities from './modules/unities'
import warranties from './modules/warranties'
import tickets from './modules/tickets'
import news from './modules/news'
import documents from './modules/documents'
import notifications from './modules/notifications'

export default {
  auth,
  users,
  financials,
  unities,
  warranties,
  tickets,
  news,
  documents,
  notifications,
}
